<template>
    <div class="main-container">
      <h5 class="pageTitle">교관정보 등록</h5>
  
      <div class="form-container">
        <!-- 이름 -->
        <div class="form-group">
          <label for="name">이름</label>
          <input v-model="form.name" type="text" class="input-box" placeholder="이름을 입력하세요" />
        </div>
  
        <!-- 자격번호 -->
        <div class="form-group">
          <label for="no">자격번호</label>
          <input v-model="form.no" type="text" class="input-box" placeholder="자격번호를 입력하세요" />
        </div>
  
        <!-- 버튼 -->
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-register" @click="registerInstructor">등록</button>
        </div>
      </div>
    </div>
  </template>

<script>
import axios from "@/axios";
import Swal from "sweetalert2";

export default {
  name: "InstructorCreate",
  data() {
    return {
      form: {
        name: "",
        no: ""
      }
    };
  },
  methods: {
    async registerInstructor() {
      try {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        const token = tokenData?.access_token || "";

       await axios.post("/master/instructor", this.form, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        Swal.fire("등록 완료", "교관 정보가 등록되었습니다.", "success");
        this.goToList();
      } catch (error) {
        console.error("등록 실패:", error);
        Swal.fire("오류", "등록 중 문제가 발생했습니다.", "error");
      }
    },
    goToList() {
      this.$router.push({ name: "master-instructorRead" });
    }
  }
};
</script>
   <style scoped>
   /* 기존 스타일 유지 */
   .main-container {
     padding: 20px;
     display: flex;
     flex-direction: column;
     align-items: center;
   }
   
   .pageTitle {
     font-weight: bold;
     margin-bottom: 40px;
     text-align: left;
     width: 100%;
   }
   
   .form-container {
     display: flex;
     flex-direction: column;
     gap: 15px;
     width: 100%;
     max-width: 800px;
   }
   
   .form-group {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     width: 100%;
   }
   
   .input-box, .select-box {
     width: 100%;
     padding: 8px;
     margin-right: 40px;
     border: 1px solid rgba(130, 128, 128, 0.26);
     border-radius: 3px;
     max-width: 600px;
   }
     .input-select {
     width: 200px;
     padding: 8px;
     border: 1px solid #ccc;
     border-radius: 4px;
   }
   .checkbox {
     margin-top: 10px;
   }
   
   .button-container {
     display: flex;
     gap: 15px;
     justify-content: flex-start;
   }
   
   .button-list {
     background-color: #ff9800;
     color: white;
     padding: 10px 20px;
     border: none;
     border-radius: 3px;
     cursor: pointer;
   }
   
   .button-register {
     background-color: #4caf50;
     color: white;
     padding: 10px 20px;
     border: none;
     border-radius: 3px;
     cursor: pointer;
     
   }
   </style>
   